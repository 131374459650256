export const siteData = {
  "name": "SPECIOM",
  "address": "23 RUE MARIE CURIE",
  "postCode": "63000",
  "city": "CLERMONT FERRAND",
  "phone": "0142369985",
  "url": "https://speciom.pro",
  "urlSub": "speciom.pro",
  "price": "price_1QS3ZbKkKc3BCKX0bCOuBlyI",
  "pk": "pk_live_51QS3NVKkKc3BCKX0rCl8t5pQgeCwE7179dUEY4iBiQC1JcFkOYurediJ39UB9ywuYsL3WUOiyBzt7XyxIaw2U0lL00qJvItaTZ",
  "sk": "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"
};